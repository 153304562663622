// document.addEventListener('DOMContentLoaded', function() {
// 		var slider = document.querySelector('.js_slider')

// 		lory(slider, {
// 			infinite: 5,
// 			enableMouseEvents: true
// 		})
// })

const headerTitle = document.getElementById('headerTitle')

const msgs = document.querySelectorAll('.mi')

function getTypingDelay(msg) {
   const len = msg.textContent.length
   return len * 25 // 50ms by character
}

anime({
    targets: msgs,
    translateX: [-30,0],
    opacity: [0,1],
    easing: "easeOutCirc",
    duration: 300,
    delay: (el, idx, l) => {
      const typingDelay = getTypingDelay(el)
      const delaySoFar = Array.from(msgs).slice(0, idx).reduce((total, curr) => total + getTypingDelay(curr), 0)
      // 500ms for "reading the previous message"
      return delaySoFar + typingDelay
    },
})


anime.timeline()
	.add({
		targets: '#headerTitle',
		translateY: [100,0],
		opacity: [0,1],
		rotate: [7, 0],
		easing: "easeInOutQuint",
		duration: 300,
		delay: function(el, i) {
			return 3 * i;
		}
	}).add({
		// targets: '.message',
		// translateY: [30,0],
		// opacity: [0,1],
		// easing: "easeOutCirc",
        // duration: 300,
        // ofsset: 100,
	}).add({
        // targets: '.message1',
        // delay: 1000
    })
	// }).add({
	// 	targets: '.btn',
	// 	translateY: [20,0],
	// 	opacity: [0,1],
	// 	easing: "easeOutCirc",
	// 	duration: 300,
	// 	offset: '-=300'
	// })

let confettiAmount = 60,
confettiColors = [
    '#7d32f5',
    '#f6e434',
    '#63fdf1',
    '#e672da',
    '#295dfe',
    '#6e57ff'
],
random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
},
createConfetti = to => {
    let elem = document.createElement('i'),
        set = Math.random() < 0.5 ? -1 : 1;
    elem.style.setProperty('--x', random(-260, 260) + 'px');
    elem.style.setProperty('--y', random(-160, 160) + 'px');
    elem.style.setProperty('--r', random(0, 360) + 'deg');
    elem.style.setProperty('--s', random(.6, 1));
    elem.style.setProperty('--b', confettiColors[random(0, 5)]);
    to.appendChild(elem);
    return elem
};

const elem = document.querySelector('#yes')

elem.addEventListener('click', e => {
    e.preventDefault();
    elem.classList.add('confetti');
    const confs = []
    for(let i = 0; i < confettiAmount; i++) {
        const c = createConfetti(elem);
        confs.push(c)
        setTimeout(() => {
            confs.forEach(c => c.remove())
        }, 600)
    }
})